import React from "react";

export default function SpeedrunAnnouncement() {
  return (
    <>
      <h2>Speedrun</h2>
      <p>
        Compete with other players in the speedrun contest! The leaderboard will
        be closed at the end of December forever. So your name stays there for
        eternity (or until we pay the bills).
      </p>
      <p>
        Best players will get{" "}
        <a href="https://www.instagram.com/p/CWV9A-WqPIU/">Fono T-Shirt</a>.
      </p>
      <p>
        To join the contest, just download{" "}
        <a href="https://store.steampowered.com/app/1513670/Fono/">
          Fono from Steam
        </a>{" "}
        and play until the end of the year, that's it.
      </p>
    </>
  );
}
